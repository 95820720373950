#video_container {
  position: relative;
}

#video_container video {
  position: absolute;
  left: 0;
  top: 0;
}

.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
